import { Box, Grid } from "@material-ui/core";
import { Button } from "@progress/kendo-react-buttons";
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import React from "react";
import { AccountApi } from "../api";
import { useApi, useNavigator } from "../hooks";
import { DataGridColumn, dataGrid } from "../infrastructure/grid/dataGrid";

export type AccountDataRow = {
  id: number | undefined;
  companyId: number;
  companyName: string;
  contactId: number;
  contactName: string;
  countryName: string;
  creationDate: Date;
  modificationDate: Date;
  validityTime: number;
  accessNumber: number;
  riskManagement: boolean;
  cropProduction: boolean;
  hasCleax: boolean;
  hasNettingAuto: boolean;
  userWsdId: number | null | undefined;
  userWsdName: string;
  activationStatus: boolean;
  currentVersion: string;
};

const DataGrid = dataGrid<AccountDataRow>();

const columns: DataGridColumn<AccountDataRow>[] = [
  {
    header: "ID",
    field: "id",
  },
  {
    header: "Société",
    field: "companyName",
  },
  {
    header: "Dernière modification",
    field: "modificationDate",
    displayFormat: "{0:d}",
  },
  {
    header: "Version WebApi",
    field: "currentVersion",
  },
  {
    header: "Nb d'accès",
    field: "accessNumber",
  },
  {
    header: "Contact",
    field: "contactName",
  },
  {
    header: "Compte WSD",
    field: "userWsdName",
  },
  {
    header: "Pays",
    field: "countryName",
  },
  {
    header: "Date de création",
    field: "creationDate",
    displayFormat: "{0:d}",
  },
  {
    header: "Gestion de position",
    field: "riskManagement",
    filterType: "boolean",
    customCellRender: (dataItem: AccountDataRow) => (
      <td style={{ textAlign: "center" }}>
        {dataItem.riskManagement ? (
          <span className="k-icon k-i-checkmark-circle"></span>
        ) : (
          <span className="k-icon k-i-close-circle"></span>
        )}
      </td>
    ),
  },
  {
    header: "Production végétale",
    field: "cropProduction",
    filterType: "boolean",
    customCellRender: (dataItem: AccountDataRow) => (
      <td style={{ textAlign: "center" }}>
        {dataItem.cropProduction ? (
          <span className="k-icon k-i-checkmark-circle"></span>
        ) : (
          <span className="k-icon k-i-close-circle"></span>
        )}
      </td>
    ),
  },
  {
    header: "CleaX",
    field: "hasCleax",
    filterType: "boolean",
    customCellRender: (dataItem: AccountDataRow) => (
      <td style={{ textAlign: "center" }}>
        {dataItem.hasCleax ? (
          <span className="k-icon k-i-checkmark-circle"></span>
        ) : (
          <span className="k-icon k-i-close-circle"></span>
        )}
      </td>
    ),
  },
  {
    header: "Netting Auto",
    field: "hasNettingAuto",
    filterType: "boolean",
    customCellRender: (dataItem: AccountDataRow) => (
      <td style={{ textAlign: "center" }}>
        {dataItem.hasNettingAuto ? (
          <span className="k-icon k-i-checkmark-circle"></span>
        ) : (
          <span className="k-icon k-i-close-circle"></span>
        )}
      </td>
    ),
  },
  {
    header: "Statut",
    field: "activationStatus",
    filterType: "boolean",
    customCellRender: (dataItem: AccountDataRow) => (
      <td style={{ textAlign: "center" }}>
        {dataItem.activationStatus ? (
          <span className="k-icon k-i-checkmark-circle"></span>
        ) : (
          <span className="k-icon k-i-close-circle"></span>
        )}
      </td>
    ),
  },
];

export const AccountListing = () => {
  const [accounts, setAccounts] = React.useState<AccountDataRow[]>([]);
  const navigator = useNavigator();
  const accountApi = useApi(AccountApi);

  React.useEffect(() => {
    const getAccounts = async () => {
      const accounts = await accountApi.accountGetAccountsGet();
      setAccounts(
        accounts.map((a) => ({
          id: a.id,
          companyId: a.companyId,
          companyName: a.companyName,
          contactName: a.contactName,
          contactId: a.contactId,
          countryName: a.countryName,
          creationDate: a.creationDate,
          modificationDate: a.modificationDate ?? a.creationDate,
          cropProduction: a.cropProduction,
          validityTime: a.validityTime,
          accessNumber: a.accessNumber,
          riskManagement: a.riskManagement,
          hasCleax: a.hasCleax,
          hasNettingAuto: a.hasNettingAuto,
          userWsdId: a.userWsdId,
          userWsdName:
            a != null && a.userWsdId != null && a.userWsdName != null
              ? a.userWsdId + " - " + a.userWsdName
              : "",
          activationStatus: a.activationStatus,
          currentVersion: a.currentVersion ?? "",
        }))
      );
    };
    getAccounts();
  }, [accountApi]);
  function handleModify(dataItem: AccountDataRow) {
    navigator.goToAccountEdition(dataItem.id, "edit");
  }

  function handleDeactivat(dataItem: AccountDataRow) {
    if (
      window.confirm(
        `Êtes vous sûr de vouloir ${dataItem.activationStatus ? "désactiver" : "activer"
        } ce compte client ?`
      ) === true
    ) {
      dataItem.activationStatus = dataItem.activationStatus ? false : true;
      accountApi.accountEditAccountPost(dataItem);
    }
  }

  function handleDuplicat(dataItem: AccountDataRow) {
    navigator.goToAccountEdition(dataItem.id, "duplicate");
  }

  let handleOnSelect = (e: any, dataItem: AccountDataRow) => {
    switch (e.item.text) {
      case "Modifier":
        handleModify(dataItem);
        break;
      case "Désactiver":
        handleDeactivat(dataItem);
        break;
      case "Activer":
        handleDeactivat(dataItem);
        break;
      case "Dupliquer":
        handleDuplicat(dataItem);
        break;
      default:
    }
  };

  function contextMenu(dataItem: AccountDataRow) {
    return (
      <Menu vertical={true} onSelect={(e) => handleOnSelect(e, dataItem)}>
        <MenuItem text="Modifier" cssStyle={{ color: "black" }} />
        <MenuItem
          text={dataItem.activationStatus ? "Désactiver" : "Activer"}
          cssStyle={{ color: "black" }}
        />
        <MenuItem text="Dupliquer" cssStyle={{ color: "black" }} />
      </Menu>
    );
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={2} textAlign="right">
            <Button primary onClick={() => navigator.goToAccountCreation()}>
              Nouveau
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <DataGrid
            data={accounts}
            columns={columns}
            contextMenu={contextMenu}
          />
        </Grid>
      </Grid>
    </>
  );
};
